import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { forwardRef } from "react";
import "./text.css";

const Schedule = (props, ref) => {
  return (
    <>
      <div
        style={{
          // width: "auto",
          // height: "auto",
          backgroundColor: "aliceblue",
          display: "flex",
          flexDirection: "column",
          padding: "25px",
        }}
      >
        <h1
          ref={ref}
          className="heading2"
          style={{
            paddingBottom: "20px",
            alignSelf: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          THE MAIN EVENT
        </h1>

        <div
          style={{
            width: "auto",
            height: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p className="textbody">
            Rosie and James would like to welcome you to{" "}
            <b>celebrate their marriage</b> on <strong>12th August</strong> with
            love, happiness and fuzzy feelings
          </p>
        </div>

        <div className="schedule-container">
          <AccessTimeIcon></AccessTimeIcon>
          <p className="textbody">
            Arrival time <strong>2.30pm</strong> for canapes and drinks.{" "}
            <br></br>If you are camping and want to come earlier or the day
            before and enjoy the festival vibes then please do!{" "}
          </p>
        </div>

        <div className="schedule-container">
          <CheckroomIcon></CheckroomIcon>
          <p className="textbody">
            Garden Party attire - no need for ties or stilettos! Wouldn't want
            to ruin anything in the ball pit...
          </p>
        </div>

        <div className="schedule-container">
          <LocationOnIcon></LocationOnIcon>
          <p className="textbody">
            Oakwood House,
            <br></br>
            Botany Lane,
            <br></br>
            Weeley Heath,
            <br></br>
            Essex,
            <br></br>
            CO16 9EG
          </p>
        </div>
      </div>
    </>
  );
};

export default forwardRef(Schedule);
