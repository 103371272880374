import "./text.css";
import React from "react";
import QRCode from "../assets/qrCode.png";
import { forwardRef } from "react";

const Money = (props, ref) => {
  return (
    <>
      <div
        style={{
          width: "auto",
          height: "auto",
          backgroundColor: "aliceblue",
          display: "flex",
          flexDirection: "column",
          padding: "25px",
        }}
      >
        <h2
          ref={ref}
          className="heading2"
          style={{
            paddingBottom: "20px",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          Wedding Gifts
        </h2>
        <div
          style={{
            width: "auto",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            className="textbody"
            style={{
              paddingInline: "40px",
              justifyContent: "center",
              alignContent: "center",
              textAlign: "center",
            }}
          >
            {" "}
            Considering we are currently squatting in Rob and Mavis' garage and
            have already spent a year away relaxing to the maxing, we are not
            really in need of gifts or a honeymoon. What we would really
            appreciate from our guests is their attendance at the Bryvig Bonanza
            and that everyone wears their dancing shoes. But if making a
            monetary contribution is something you would like to do, then who
            are we to stand in your way! There is a collection pot set up{" "}
            <a href="https://app.collectionpot.com/pot/rosieandjamespot/">
              here
            </a>
            . We will most likely buy something edible or childish.
          </p>
          <div>
            <a href="https://app.collectionpot.com/pot/rosieandjamespot/">
              <img src={QRCode} alt="QRCode"></img>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef(Money);
