import React from "react";
import "./SlideinLeft.css";
import forever from "../assets/forever.jpeg";
import "./Backgroundimage.css";
import Confetti from "./Confetti";

const ForLikeEver = ({
  handleScheduleClick,
  handleAccommodationClick,
  handleFoodClick,
  handleTransportClick,
  handleMoneyClick,
  handleExtrasClick,
}) => {
  const tabs = [
    { label: "SCHEDULE", handleClick: handleScheduleClick },
    { label: "ACCOMMODATION", handleClick: handleAccommodationClick },
    { label: "FOOD", handleClick: handleFoodClick },
    { label: "TRANSPORT", handleClick: handleTransportClick },
    { label: "GIFTS", handleClick: handleMoneyClick },
    { label: "EXTRAS", handleClick: handleExtrasClick },
  ];

  return (
    <>
      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${forever})`,
        }}
        className="background-image"
      >
        <div>
          <h1 className="slideInLeft">FOR</h1>
          <h1 className="slideInLeft" style={{ animationDelay: "1s" }}>
            LIKE
          </h1>
          <h1 className="slideInLeft" style={{ animationDelay: "2s" }}>
            EVER
          </h1>
        </div>
        <Confetti></Confetti>
        <div
          className="tabcontainer"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <button
                key={index}
                className="pinkbutton"
                onClick={tab.handleClick}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ForLikeEver;
