import React, { useRef } from "react";
import "./SlideinLeft.css";
import "./Backgroundimage.css";
import ForLikeEver from "./ForLikeEver";
import Money from "./Money";
import Schedule from "./Schedule";
import Transport from "./Transport";
import Accommodation from "./Accomodation";
import Food from "./Food";
import Extras from "./Extras";
import Countdown from "./Countdown";

const Wedding = () => {
  const scheduleRef = useRef(null);
  const transportRef = useRef(null);
  const accommodationRef = useRef(null);
  const foodRef = useRef(null);
  const moneyRef = useRef(null);
  const extrasRef = useRef(null);

  const handleScheduleClick = () => {
    scheduleRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleTransportClick = () => {
    transportRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleAccommodationClick = () => {
    accommodationRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleFoodClick = () => {
    foodRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleMoneyClick = () => {
    moneyRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleExtrasClick = () => {
    extrasRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div styles={{ zoom: "inital" }}>
      <ForLikeEver
        handleScheduleClick={handleScheduleClick}
        handleTransportClick={handleTransportClick}
        handleAccommodationClick={handleAccommodationClick}
        handleFoodClick={handleFoodClick}
        handleMoneyClick={handleMoneyClick}
        handleExtrasClick={handleExtrasClick}
      ></ForLikeEver>
      <Countdown></Countdown>
      <Schedule ref={scheduleRef}></Schedule>
      <Transport ref={transportRef}></Transport>
      <Accommodation ref={accommodationRef}></Accommodation>
      <Food ref={foodRef}></Food>
      <Money ref={moneyRef}></Money>
      <Extras ref={extrasRef}></Extras>
    </div>
  );
};

export default Wedding;
