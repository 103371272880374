import "./text.css";
import React, { useState, useEffect } from "react";

const Countdown = () => {
  const [countdown, setCountdown] = useState("");

  useEffect(() => {
    const countDownDate = new Date("Aug 12, 2023 14:30:00").getTime();

    const updateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

      const answer =
        days + " days " + hours + " hours " + minutes + " minutes!!! ";

      setCountdown(answer);
    };

    const countdownInterval = setInterval(updateCountdown, 30000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <div
      style={{
        width: "auto",
        height: "auto",
        backgroundColor: "aliceblue",
        display: "flex",
        flexDirection: "column",
        padding: "25px",
      }}
    >
      <h2
        className="heading2"
        style={{
          //   paddingBottom: "px",
          alignSelf: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {countdown}
      </h2>
    </div>
  );
};

export default Countdown;
