import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";

const Protected = ({ auth, children }) => {
  const navigate = useNavigate();
  if (!auth) {
    setTimeout(() => {
      navigate("/");
    }, 3000);
    return (
      <div>
        <div
          style={{
            textAlign: "center",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          Either you got the password wrong or you're not invited...
          <br></br>
          <br></br>I will redirect you.
          <br></br>
          <Spinner />
        </div>
      </div>
    );
  }
  return children;
};
export default Protected;
