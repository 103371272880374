import React from "react";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import PhoneIcon from "@mui/icons-material/Phone";
import { forwardRef } from "react";
import "./text.css";

const Extras = (props, ref) => {
  return (
    <>
      <div
        style={{
          width: "auto",
          height: "auto",
          backgroundColor: "aliceblue",
          display: "flex",
          flexDirection: "column",
          padding: "25px",
        }}
      >
        <h2
          ref={ref}
          className="heading2"
          style={{
            paddingBottom: "20px",
            alignSelf: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Extra Info
        </h2>

        <div className="schedule-container">
          <CoronavirusIcon></CoronavirusIcon>
          <p className="textbody">
            Oakwood contains every single allergen. We will do our best to move
            most of the animals but truthfully it is in the air. We have dogs,
            cats, ponies, birds, grass, hay, wheat and nuts, so please please
            take as much Piriton as possible as soon as you hit the Essex
            border.
          </p>
        </div>

        <div className="schedule-container">
          <PhoneIcon></PhoneIcon>
          <p className="textbody">
            Rosie - 07526 438257 <br></br>James- 07866 974062
          </p>
        </div>
      </div>
    </>
  );
};

export default forwardRef(Extras);
