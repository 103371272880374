import React, { forwardRef } from "react";
import "./cssblog.css";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.locale("en-gb");

const Post = ({ post, index }, ref) => {
  const createMarkup = (text) => {
    return text.split("\n").map((line, i) => (
      <React.Fragment key={i}>
        {line}
        <br />
        <br />
      </React.Fragment>
    ));
  };

  return (
    <>
      <div className="post-container" ref={ref}>
        <div className="new-message">
          <p>New Message</p>
        </div>
        <div className="to-from">
          <div className="to-from-row">
            <p>To:</p>
            <div className="empty-input" id="invisible">
              <p> .</p>
            </div>
          </div>
          <div className="to-from-row">
            <p>Subject:</p>
            <div className="empty-input">{post.title}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <h3 className="date">
            {dayjs(post.date, "DD.MM.YYYY").format("dddd D MMM YYYY")}
          </h3>
        </div>

        <p className="blog-text">{createMarkup(post.text)}</p>
        <div className="bottom-bar">
          <div className="fake-button">
            <p>Sent</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default forwardRef(Post);
