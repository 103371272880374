import React from "react";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { forwardRef } from "react";
import "./text.css";

const Food = (props, ref) => {
  return (
    <>
      <div
        style={{
          width: "auto",
          height: "auto",
          backgroundColor: "aliceblue",
          display: "flex",
          flexDirection: "column",
          padding: "25px",
        }}
      >
        <h2
          ref={ref}
          className="heading2"
          style={{
            paddingBottom: "20px",
            alignSelf: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Food
        </h2>

        <div className="schedule-container">
          <RestaurantIcon></RestaurantIcon>
          <p className="textbody">
            I hope we have already considered everyone's dietary requirements
            but if there is something you are allergic to, please let us know!
            Vegetarian/Vegan is already catered for
          </p>
        </div>
      </div>
    </>
  );
};

export default forwardRef(Food);
