import React from "react";
import FestivalIcon from "@mui/icons-material/Festival";
import { forwardRef } from "react";
import "./text.css";

const Accommodation = (props, ref) => {
  return (
    <>
      <div
        style={{
          width: "auto",
          height: "auto",
          backgroundColor: "aliceblue",
          display: "flex",
          flexDirection: "column",
          padding: "25px",
        }}
      >
        <h2
          ref={ref}
          className="heading2"
          style={{
            paddingBottom: "20px",
            alignSelf: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Accommodation
        </h2>

        <div className="schedule-container">
          <FestivalIcon></FestivalIcon>
          <p className="textbody">
            We have organised Glamping tents for the riff-raff (you know who you
            are!) They have no power but they do have proper beds and 10.5 tog
            duvets! (oooooo luxury)
            <br></br>
            For our more esteemed guests, Clacton-on-Sea has loads of hotels and
            is only 10 minutes away. Thorpe-le-soken also has a few BnB's, or if
            van life is your thing we recommend Homestead Lake Park as they have
            a wicked cafe.
          </p>
        </div>
      </div>
    </>
  );
};

export default forwardRef(Accommodation);
