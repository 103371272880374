import "./cssblog.css";
import readyfortakeoff from "../assets/readyfortakeoff.JPG";
import rosieBA from "../assets/rosieBA.jpeg";
import day1 from "../assets/day1.jpeg";
import singaporeair from "../assets/singaporeair.jpeg";
import jtrain from "../assets/jtrain.jpeg";

const CoverPhoto = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyontent: "space-between",
          overflowX: "scroll",
        }}
      >
        <img
          src={readyfortakeoff}
          style={{ height: "35vh", margin: "0 auto" }}
          alt="ready for take off"
        />
        <img
          src={rosieBA}
          style={{ height: "35vh", margin: "0 auto" }}
          alt="rosie BA"
        />
        <img
          src={day1}
          style={{ height: "35vh", margin: "0 auto" }}
          alt="day 1 james on a train"
        />
        <img
          src={singaporeair}
          style={{ height: "35vh", margin: "0 auto" }}
          alt="sinagpore air"
        />
        <img
          src={jtrain}
          style={{ height: "35vh", margin: "0 auto" }}
          alt="james on a train"
        />
      </div>
    </>
  );
};

export default CoverPhoto;
