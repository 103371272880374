import React, { useState, useEffect } from "react";
import Background from "./Background.jpeg";
import gran1 from "./gran1.png";
import namesOfGrans from "../mocks"

const Annas_gran = () => {
    const [originalWord, setOriginalWord] = useState("");
    const [scrambledWord, setScrambledWord] = useState("")
    const [userAnswer, setUserAnswer] = useState("");
    const [result, setResult] = useState("");
    const [imageArray, appendImage] = useState([]);

    const scrambleWord = (word) => {
        return word.split("").sort(() => Math.random() - 0.5).join("");
    };

    const handleInputChange = (e) => {
        setUserAnswer(e.target.value);
    };

    const startNewGame = () => {
        const randomIndex = Math.floor(Math.random() * namesOfGrans.namesOfGrans.length);
        setScrambledWord(scrambleWord(namesOfGrans.namesOfGrans[randomIndex]));
        setOriginalWord(namesOfGrans.namesOfGrans[randomIndex]);

        setUserAnswer("");
        setResult("");
    };

    const handleUnscramble = () => {
        if (userAnswer.toLowerCase() === originalWord.toLowerCase()) {
            setResult("Yes - you win a Gran");
            appendImage(imageArray.concat({ top: (Math.random() * 90), left: (Math.random() * 90) }))
        } else {
            setResult("Noooo! Getting a new name...");
        }

        setTimeout(startNewGame, 2000);
    };

    useEffect(() => {
        startNewGame();
    }, []);

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${Background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "100vh",
                    position: "relative",
                }}
            >

                {imageArray.map((image, index) => (
                    <img
                        key={index}
                        src={gran1}
                        alt={`Appended Image ${index + 1}`}
                        style={{
                            position: "absolute",
                            top: `${image.top}%`, 
                            left: `${image.left}%`, 
                            maxWidth: "170px", 
                            maxHeight: "170px", 
                        }}
                    />
                ))}
                <div class="fixed inset-0 flex justify-center items-center z-50">
                    <div class="align-center text-center p-8">
                        <h1 class="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">Who is Anna's Gran?</h1>
                        <p class="mb-4 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">Unscramble the name of a Nan to get another Gran. More correct Grannies, the more Nannas fill your screen!</p>
                        <h1 class="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">{scrambledWord}</span></h1>
                        <div>
                            <input
                                type="text"
                                class="bg-green-50 border border-green-300 mt-3"
                                id="useranswer"
                                value={userAnswer}
                                onChange={handleInputChange}
                                />
                            <button 
                                id="button" 
                                class="ml-2 px-1 rounded-xl bg-indigo-200 hover:bg-sky-700 "
                                onClick={handleUnscramble}
                            >
                                Un-scramble
                            </button>
                        </div>
                        <div className="result">
                            <p class="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">{result}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Annas_gran;
