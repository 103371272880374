import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuth } from "./redux/authSlice";
import SantaMonica from "./assets/SantaMonica.jpeg";
import { useNavigate } from "react-router-dom";
import BlobButton from "./BlobButton";

function Layer1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [guess, setGuess] = useState("");

  function handleChange(event) {
    setGuess(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (guess === process.env.REACT_APP_PASSWORD) {
      dispatch(setAuth(true));
    }
    setGuess("");
    navigate("/wedding");
  }

  function handleAnnasGran() {
    navigate("/annas-gran");
  }

  function handleSecretButton() {
    setTimeout(() => {
      navigate("/blog");
    }, 600);
  }

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${SantaMonica})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          position: "relative",
        }}
      >
        <div class=" flex-row text-center p-5">
          <h1 class="text-xl font-bold underline">Rosie Dyvig  | Software Engineer </h1>
          <h3 className="m-4 text-xl multicolortext">made for work and play</h3>
          <div class=" bg-indigo-500 bg-opacity-10 text-center mx-5 rounded p-3 m-2">
            <p class="text-sm"> 
              Coding is a passion and I am lucky to be working for a fantasic
              digital marketing agency based in Birmingham. I left project
              management within the construction industy, went traveling and now
              this is what I do. <br></br> I am a Codeworks graduate, full stack
              engineer and a very messy cook.
            </p>
          </div>
          <div class="bg-indigo-500 bg-opacity-10 flex rounded justify-center mx-5 items-center">
            <table class="text-sm table-auto border-separate border-spacing-2 p-5">
            <tbody>
                <tr>
                  <td class="mx-2 text-right">Wedding Website - </td>
                  <td>
                    <form onSubmit={handleSubmit}>
                      <input
                        class="bg-blue-50 border border-blue-300"
                        type="password"
                        value={guess}
                        onChange={handleChange}
                        disabled={false}
                        placeholder="Enter Password"
                        />
                      <button
                        class="bg-indigo-500 hover:bg-sky-700 text-white font-bold ms-3 py-1 px-4 rounded"
                        type="submit"
                        onClick={() => {
                          console.log("click");
                        }}
                        >
                        {" "}
                        Submit{" "}
                      </button>
                    </form>
                  </td>
                </tr>
                <tr class="mt-3">
                  <td class="text-right">Travel Blog - </td>
                  <td>
                    <BlobButton
                      text={"get your passport"}
                      handleClick={handleSecretButton}
                      ></BlobButton>
                  </td>
                </tr>
                <tr class="mt-3">
                  <td class="text-right">Annas Gran - </td>
                  <td>
                    <button 
                      class="bg-indigo-500 hover:bg-sky-700 text-white font-bold ms-3 py-1 px-4 rounded"
                      onClick={handleAnnasGran}>
                        Launch Game
                      </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Layer1;
