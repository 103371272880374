import React from "react";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import TrainIcon from "@mui/icons-material/Train";
import HailIcon from "@mui/icons-material/Hail";
import { forwardRef } from "react";
import "./text.css";

const Transport = (props, ref) => {
  return (
    <>
      <div
        style={{
          width: "auto",
          height: "auto",
          backgroundColor: "aliceblue",
          display: "flex",
          flexDirection: "column",
          padding: "25px",
        }}
      >
        <h2
          ref={ref}
          className="heading2"
          style={{
            paddingBottom: "20px",
            alignSelf: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          Transport
        </h2>

        <div className="schedule-container">
          <TimeToLeaveIcon></TimeToLeaveIcon>
          <p className="textbody">
            There is parking available at Oakwood. If you need a space drop me
            or James a message!
            <br></br> From Birmingham just follow the M6 East until you hit the
            sea
          </p>
        </div>
        <div className="schedule-container">
          <TrainIcon></TrainIcon>
          <p className="textbody">
            The closest train station is Thorpe-le-Soken which is 5 mins away
            from the house and just over an hour away from London Liverpool
            Street. Let us know when your train arrives and we (James' dad) will
            come and get you!
          </p>
        </div>
        <div className="schedule-container">
          <HailIcon></HailIcon>
          <p className="textbody">
            There is one taxi company in Weeley - its Billy and his wife. There
            are heaps in Clacton so taxis wont be a problem hopefully!
          </p>
        </div>
      </div>
    </>
  );
};

export default forwardRef(Transport);
