const namesOfGrans = [
    "Susan", "Linda", "Margaret", "Patricia", "Christine", "Jennifer", "Elizabeth", "Karen", "Anne", "Jane",
    "Mary", "Deborah", "Caroline", "Julie", "Sarah", "Tracey", "Angela", "Alison", "Helen", "Paula",
    "Victoria", "Sharon", "Jacqueline", "Joanne", "Amanda", "Wendy", "Samantha", "Stephanie", "Janet", "Lesley",
    "Diane", "Nicola", "Debbie", "Julia", "Lesley", "Dawn", "Kathryn", "Michelle", "Tracy", "Teresa",
    "Emma", "Tina", "Claire", "Jayne", "Alison", "Donna", "Karen", "Sheila", "Jane", "Gillian",
    "Jacqueline", "Nicola", "Shirley", "Carol", "Mandy", "Yvonne", "Sandra", "Lorraine", "Vicki", "Fiona",
    "Lynne", "Theresa", "Pamela", "Helen", "Gillian", "Tracy", "Sharon", "Julie", "Andrea", "Joanne",
    "Jane", "Maxine", "Janet", "Michelle", "Louise", "Kim", "Catherine", "Samantha", "Julie", "Fiona",
    "Claire", "Jane", "Alison", "Amanda", "Lorraine", "Julia", "Katherine", "Christine", "Joanna", "Heather"
];

module.exports = { namesOfGrans };