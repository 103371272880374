import React, { useEffect, useRef, useState } from "react";
import "./text.css";
import JSConfetti from "js-confetti";

const Confetti = () => {
  const jsConfetti = new JSConfetti();
  const [isMoving, setMoving] = useState(true);
  const dvdRef = useRef(null);
  let x_incr = 1;
  let y_incr = 1;

  useEffect(() => {
    let intervalId;

    if (isMoving) {
      intervalId = setInterval(frame, 5);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isMoving]);

  function toggleMoving() {
    setMoving(!isMoving);
  }

  function handleCollision() {
    const dvd = dvdRef.current;
    if (!dvd) {
      return;
    }
    const dvdHeight = dvd.offsetHeight;
    const dvdWidth = dvd.offsetWidth;
    const left = dvd.offsetLeft;
    const top = dvd.offsetTop;
    const winHeight = window.innerHeight;
    const winWidth = window.innerWidth;

    if (left <= 0 || left + dvdWidth >= winWidth) {
      x_incr = ~x_incr + 1;
    }
    if (top <= 0 || top + dvdHeight >= winHeight) {
      y_incr = ~y_incr + 1;
    }
  }

  function frame() {
    const dvd = dvdRef.current;
    if (!dvd) {
      return;
    }
    handleCollision();
    dvd.style.top = dvd.offsetTop + y_incr + "px";
    dvd.style.left = dvd.offsetLeft + x_incr + "px";
  }

  const handleClick = () => {
    jsConfetti.addConfetti({
      confettiNumber: 1000,
      confettiRadius: 6,
      confettiColors: [
        "#a864fd",
        "#29cdff",
        "rgb(224, 29, 101)",
        "#78ff44",
        "#4169e1",
        "#ff718d",
        "#fdff6a",
      ],
    });
    toggleMoving();
  };

  return (
    <button
      onClick={handleClick}
      id="dvd"
      ref={dvdRef}
      className="confettiBtn"
      style={{
        position: "absolute",
        left: "80px",
        top: "20px",
        textAlign: "center",
        width: "60px",
        height: "60px",
        backgroundColor: "blue",
        color: "white",
        border: "none",
        borderRadius: "50%",
      }}
    >
      Click Me
    </button>
  );
};

export default Confetti;
