import "./App.css";
import Layer1 from "./Layer1";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blog from "./Blog/Blog";
import Wedding from "./Wedding/Wedding";
import Protected from "./Protected";
import Annas_gran from "./Annas-gran/Annas-gran";

function App() {
  const auth = useSelector((s) => s.auth);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Layer1 />} />
        <Route
          path="/blog"
          element={
            <Blog />
          }
        />
        <Route
          path="/wedding"
          element={
            <Protected auth={auth}>
              <Wedding />
            </Protected>
          }
        />
        <Route
          path="/annas-gran"
          element={
            <Annas_gran />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
