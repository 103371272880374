import Alice from "../assets/Alice.jpeg";
const Intro = () => {
  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${Alice})`,
        height: "50vh",
        backgroundPosition: "100% 29%",
        backgroundSize: "cover",
        width: "100vw",
        color: "blue",
        fontSize: "2em",
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: "10px",
        textShadow:
          "-1px -1px 0 #FFFFFF, 1px -1px 0  #FFFFFF, -1px 1px 0 #FFFFFF, 1px 1px 0 #FFFFFF",
      }}
    >
      <h1>June 2022 - Jan 2023</h1>
      <p>
        {" "}
        Weekly emails to family and friends writen during our break. Compiled
        here a year later.{" "}
      </p>
    </div>
  );
};

export default Intro;
