import { forwardRef } from "react";
import "./cssblog.css";
import localizedFormat from "dayjs/plugin/localizedFormat";
const dayjs = require("dayjs");
dayjs.extend(localizedFormat);

dayjs.locale("en-gb");

const Sidebar = ({ mocks, scrollToPost }, ref) => {
  const handleClick = (index) => {
    //console.log("clicked", index);
    scrollToPost(index);
  };
  return (
    <>
      <div className="sidebar-container">
        <h1>Contents</h1>
        {mocks.map((x, i) => {
          return (
            <p className="sidebar-text" key={i} onClick={() => handleClick(i)}>
              {dayjs(x.date, "DD.MM.YYYY").format("D MMM YY")} - {x.title}
            </p>
          );
        })}
      </div>
    </>
  );
};

export default forwardRef(Sidebar);
