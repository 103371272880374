import "./blobbutton.css";
const BlobButton = ({ handleClick, text }) => {
  return (
    <>
      <button className="blob-btn" onClick={handleClick}>
        {text}
        <span className="blob-btn__inner">
          <span className="blob-btn__blobs">
            <span className="blob-btn__blob"></span>
            <span className="blob-btn__blob"></span>
            <span className="blob-btn__blob"></span>
            <span className="blob-btn__blob"></span>
          </span>
        </span>
      </button>
    </>
  );
};

export default BlobButton;
